<template>
    <picture>
      <source type="image/webp"
              :srcset="
              `${src.image.size.size3_webp ? src.image.size.size3_webp : src.image.url}` + ' 1x, ' +
              `${src.image.size.size2_webp ? src.image.size.size2_webp : src.image.url}` + ' 2x'">
      <img class="img-cover" :alt="src.name"
           :srcset="
              `${src.image.size.size3 ? src.image.size.size3 : src.image.url}` + ' 1x, ' +
              `${src.image.size.size2 ? src.image.size.size2 : src.image.url}` + ' 2x'"
           :src="src.image.url">
    </picture>
</template>

<script>
export default {
  name: 'ResponsiveImages',
  props: ['src']
}
</script>

<style scoped>

</style>
